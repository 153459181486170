import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { ChevronRightIcon } from "@heroicons/react/solid";
import FreeVideo from "../../components/video/freeVideo";
import Seo from "../../components/seo";

const VideoJson = () => {
  const videoJson = {
    slug: "seminarbackup-2",
    series: "seminarbackup",
    no: 2,
    title: "水野コンサルタンシー第46回ビジネス講習会（第1部）",
    category: "【その他】",
    created: "2023-02-08",
    description: "水野コンサルタンシー第46回ビジネス講習会（第1部）",
    type: "free",
  };

  return (
    <Layout>
      <Seo title={videoJson.title} />
      <main>
        <div className="bg-gray-100 text-gray-600">
          <nav className="container mx-auto p-1 md:p-3 font-sans">
            <ol className="list-reset flex items-center text-sm">
              <li>
                <Link to="/" className="text-blue-600 hover:text-blue-700">
                  ホーム
                </Link>
              </li>
              <li className="mx-1">
                <ChevronRightIcon className="w-4 h-4" />
              </li>
              <li>
                <Link
                  to="/videos"
                  className="text-blue-600 hover:text-blue-700"
                >
                  中国ビジネス解説動画
                </Link>
              </li>
              <li className="mx-1">
                <ChevronRightIcon className="w-4 h-4" />
              </li>
              <li>
                {videoJson.title.length > 11
                  ? videoJson.title.substring(0, 10) + "..."
                  : videoJson.title}
              </li>
            </ol>
          </nav>
        </div>
        <div className="container mx-auto max-w-5xl px-5 md:px-10 py-10 md:py-20">
          <FreeVideo videoJson={videoJson} withDoc={false} />
          <div className="font-sans mt-5">
            <h1 className="md:text-2xl text-lg font-medium mb-3">
              {videoJson.title}
            </h1>
            <div className="text-sm mb-3">
              {videoJson.created}
              {videoJson.category ? " | " + videoJson.category : null}
            </div>
            <div>{videoJson.description}</div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default VideoJson;
